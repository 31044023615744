
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  data() {
    return {
      active: 0,
    };
  },
  mounted() {},
  methods: {
    back() {
      this.$router.back();
    },
  },
});
