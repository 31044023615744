<template>
  <div class="modelkuang" v-if="isShow" @click="hide">
    <div class="modelbg"></div>
    <div class="imageviewerbox">
      <div class="imgbox" @click.stop="">
        <img :src="imgs[imgIndex]" alt="" />
        <span class="close" @click="hide">关闭</span>
      </div>
      <div class="imgban">
        <img
          :class="{ active: i == imgIndex }"
          v-for="(item, i) in imgs"
          :key="i"
          :src="item"
          @click.stop="imgIndex = i"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const show = ref(false);
export default {
  name: "imageviewer",
  props: {
    imgs: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isShow: false,
      imgIndex: 0,
    };
  },
  methods: {
    show(i) {
      this.isShow = true;
      this.imgIndex = i || 0;
      if (this.imgs.length == 0) {
        this.hide();
      }
    },
    hide() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.modelkuang {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: none;

  .modelbg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    transform: scale(2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.imageviewerbox {
  z-index: 10;
  width: 100%;
  height: 100%;
  padding-bottom: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imgbox {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 800px;
    img {
      height: 800px;
      width: auto;
    }

    .close {
      position: absolute;
      right: -100px;    
      width: 100px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
    }
  }
  .imgban {
    height: 102px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      display: block;
      height: 100px;
      margin: 0 5px;
      cursor: pointer;
      box-sizing: border-box;
      border: solid 1px rgba(0, 0, 0, 0);

      &.active {
        border-color: #fdfdff;
      }
    }
  }
}

</style>
