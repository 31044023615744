
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from 'vue-router'
import {
  VideoCameraOutlined,
  AppstoreOutlined,
  CommentOutlined,
  CompassOutlined,
  GlobalOutlined,
  PaperClipOutlined,
  SafetyCertificateOutlined,
  ReadOutlined,
  SyncOutlined,
} from "@ant-design/icons-vue";
export default defineComponent({
  setup() {
    const current = ref<string[]>(["/"]);
    const $router  = useRouter();
    const jump = ({key}) => {

      if (key !== 'admin-front') {
        $router.push({path: key});
      }else{
        let location = $router.currentRoute.value.path;
        $router.push({path: location});
        setTimeout(() => {
          location = location.replace('/detail','');
          current.value = [ location ];
        }, 2000);
      }
    }
    onMounted(() => {
      setTimeout(() => {
        let location = $router.currentRoute.value.path;
        location = location.replace('/detail','');
        current.value = [ location ];
      }, 1500);
    });
    return {
      current,
      jump,
    };
  },
  components: {
    VideoCameraOutlined,
    AppstoreOutlined,
    CommentOutlined,
    CompassOutlined,
    GlobalOutlined,
    PaperClipOutlined,
    SafetyCertificateOutlined,
    ReadOutlined,
    SyncOutlined,
  },
});
