import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("../page/index/index.vue"),
  },
  {
    path: "/wang",
    name: "wang",
    component: () => import("../page/m2wang/index.vue"),
    children: [
      {
        path: "/wang",
        name: "dataview",
        component: () => import("../views/indexdata/index.vue"),
      },
      {
        path: "/wang/monitor",
        name: "wangmonitor",
        component: () => import("../views/monitor-cur/index.vue"),
      },
      {
        path: "/wang/chart",
        name: "chart",
        component: () => import("../page/m2wang/chart.vue"),
      },
      {
        path: "/wang/shuifei",
        name: "shuifei",
        component: () => import("../page/m2wang/shuifei.vue"),
      },
      {
        path: "/wang/chongqing",
        name: "chongqing",
        component: () => import("../page/m2wang/chongqing.vue"),
      },
      {
        path: "/wang/yongdi",
        name: "wangyongdi",
        component: () => import("../page/m2wang/yongdi.vue"),
      },
      {
        path: "/wang/jingji",
        name: "wangjingji",
        component: () => import("../page/m2wang/jingji.vue"),
      },
      {
        path: "/wang/fixed-asset",
        name: "wang-fixed-asset",
        component: () => import("../page/m2wang/fixed-asset.vue"),
      },
    ],
  },
  {
    path: "/suyuan",
    name: "suyuan",
    component: () => import("../page/m3suyuan/index.vue"),
    children: [
      {
        path: "/suyuan/nsjl",
        name: "suyuanny",
        component: () => import("../page/m3suyuan/nsjl.vue"),
      },
      {
        path: "/suyuan/kcjl",
        name: "suyuankcjl",
        component: () => import("../page/m3suyuan/kcjl.vue"),
      },
      ///suyuan/ncpxs
      {
        path: "/suyuan/ncpxs",
        name: "ncpxs",
        component: () => import("../page/m3suyuan/ncpxs.vue"),
      },
      {
        path: "/suyuan/nsxcx",
        name: "nsxcx",
        component: () => import("../page/m3suyuan/nsxcx.vue"),
      },
    ],
  },
  {
    path: "/zhuanjia",
    name: "zixunzixun",
    component: () => import("../page/m4zhuanjia/shouye.vue"),
  },
  {
    path: "/zhuanjia/list",
    name: "zhuanjia",
    component: () => import("../page/m4zhuanjia/index.vue"),
    children: [
      {
        path: "/zhuanjia/list",
        name: "zixunzixunlist",
        component: () => import("../page/m4zhuanjia/z.vue"),
      },
      {
        path: "/zhuanjia/zdetails",
        name: "zixunzixunzdetails",
        component: () => import("../page/m4zhuanjia/zdetails.vue"),
      },
      {
        path: "/zhuanjia/zhishi",
        name: "zixunzhishi",
        component: () => import("../page/m4zhuanjia/zhishi.vue"),
      },
      {
        path: "/zhuanjia/zhishidetails",
        name: "zixunzhishidetails",
        component: () => import("../page/m4zhuanjia/zhishidetails.vue"),
      },
      {
        path: "/zhuanjia/wenda",
        name: "zixunwenda",
        component: () => import("../page/m4zhuanjia/wenda.vue"),
      },
      {
        path: "/zhuanjia/applet",
        name: "zixunapplet",
        component: () => import("../page/m4zhuanjia/applet.vue"),
      },
    ],
  },
  {
    path: "/meilin",
    name: "meilin",
    component: () => import("../page/m5meilin/index.vue"),
    children: [
      {
        path: "/meilin",
        name: "meilinjkyd",
        meta: {
          keepAlive: true,
        },
        component: () => import("../page/m5meilin/jkyd.vue"),
      },
      {
        path: "/meilin/ptqy",
        name: "ptqy",
        meta: {
          keepAlive: true,
        },
        component: () => import("../page/m5meilin/ptqy.vue"),
      },
      {
        path: "/meilin/yxhl",
        name: "yxhl",
        meta: {
          keepAlive: true,
        },
        component: () => import("../page/m5meilin/yxhl.vue"),
      },
      {
        path: "/meilin/yxmlspdl",
        name: "yxmlspdl",
        component: () => import("../page/m5meilin/yxmlspdl.vue"),
      },
      {
        path: "/meilin/cyfwdw",
        name: "cyfwdw",
        component: () => import("../page/m5meilin/cyfwdw.vue"),
      },
      {
        path: "/meilin/cyfwdwxq",
        name: "cyfwdwxq",
        component: () => import("../page/m5meilin/cyfwdwxq.vue"),
      },
      {
        path: "/meilin/wzxq",
        name: "wzxq",
        component: () => import("../page/m5meilin/wzxq.vue"),
      },
      {
        path: "/meilin/wzxq1",
        name: "wzxq1",
        component: () => import("../page/m5meilin/wzxq1.vue"),
      },
      {
        path: "/meilin/wzxq2",
        name: "wzxq2",
        component: () => import("../page/m5meilin/wzxq2.vue"),
      },
      {
        path: "/meilin/cyyq",
        name: "cyyq",
        component: () => import("../page/m5meilin/cyyq.vue"),
      }, {
        path: "/meilin/mdcyfwdw",
        name: "mdcyfwdw",
        component: () => import("../page/m5meilin/mdcyfwdw.vue"),
      }, {
        path: "/meilin/gdzcgl",
        name: "gdzcgl",
        component: () => import("../page/m5meilin/gdzcgl.vue"),
      }
    ],
  },
  {
    path: "/zixun",
    name: "zixun",
    component: () => import("../page/m6zixun/index.vue"),
    children: [
      {
        path: "/zixun",
        name: "zixunjianjie",
        redirect: '/zixun/pingtong'
        // component: () => import("../page/m6zixun/pingwu.vue"),
      },
      {
        path: "/zixun/dsztc",
        name: "dsztc",
        component: () => import("../page/m5meilin/dsztc.vue"),
      },
      {
        path: "/zixun/pingtong",
        name: "zixunpingtong",
        component: () => import("../page/m6zixun/pingtong.vue"),
      },
      {
        path: "/zixun/zhengce",
        name: "zixunzhengce",
        component: () => import("../page/m6zixun/zhengce.vue"),
      },
      {
        path: "/zixun/zhengceInfo",
        name: "zixunzhengceInfo",
        component: () => import("../page/m6zixun/zhengceInfo.vue"),
      },
      {
        path: "/zixun/nongcp",
        name: "zixunnongcp",
        component: () => import("../page/m6zixun/nongcp.vue"),
      },
      {
        path: "/zixun/nongcpInfo",
        name: "zixunnongcpInfo",
        component: () => import("../page/m6zixun/nongcpInfo.vue"),
      },
      {
        path: "/zixun/jiagongcp",
        name: "zixunjiagongcp",
        component: () => import("../page/m6zixun/jiagongcp.vue"),
      },
      {
        path: "/zixun/jiagongcpInfo",
        name: "zixunjiagongcpInfo",
        component: () => import("../page/m6zixun/jiagongcpInfo.vue"),
      },
      {
        path: "/zixun/fuwu",
        name: "zixunfuwu",
        component: () => import("../page/m6zixun/fuwu.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
