<template>
  <div>
    <a-pagination
      size="small"
      :current="currentPage"
      :pageSize="pageSize"
      :total="total"
      @change="pageChange"
    />
  </div>
</template>

<script>
import { ref } from "vue";
const show = ref(false);
export default {
  name: "paging",
  props: {
    icon: {
      type: String,
      default: "topicon",
    },
    pageSize: {
      type: Number,
      default: 6,
    },
    total: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
      items: [],
    };
  },
  mounted() {
    this.pageChange(1);
  },
  methods: {
    pageChange(page) {
      this.currentPage = page;
      this.$emit("pageChange", page, this.pageSize);
    },
  },
};
</script>

<style scoped>


</style>
