<template>
  <div class="myiconbox">
    <img :src="imgSrc" />
  </div>
</template>

<script>
import { ref } from "vue";
const show = ref(false);
export default {
  name: "myicon",
  props: {
    icon: {
      type: String,
      default: "topicon",
    },
  },
  setup(props) {
    var imgSrc = require("@/assets/iconimg/" + props.icon + ".png");
    return { imgSrc };
  },
};
</script>

<style scoped>

.myiconbox {
  display: inline-block;
}

</style>
