import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less';
import axios from 'axios';

import DataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 引入图表（所有图标见 icon 目录下的 demo_index.html）
import './assets/icon/iconfont.css';
import './assets/icon_font/iconfont.css';
// 引入 全局注册组件
import PublicComponent from '@/components/componentInstall';
import 'windi.css';
import 'ant-design-vue/dist/antd.css';
import 'animate.css';
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.dark.css';

// axios.defaults.baseURL = 'https://pingwu.leleshuju.com/';

// 这个是为了兼容 m3u8
// const hls = require('videojs-contrib-hls')
// Vue.use(hls)
const app = createApp(App)
app.use(PublicComponent);
app.use(store);
app.use(Antd);
app.use(router);
app.use(less);
app.use(DataV)
app.mount('#app')