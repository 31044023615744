
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import {
  CompassOutlined,
  CloudServerOutlined,
  SafetyCertificateOutlined,
  TeamOutlined,
  YuqueOutlined,
  PaperClipOutlined,
  SettingOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CompassOutlined,
    CloudServerOutlined,
    SafetyCertificateOutlined,
    TeamOutlined,
    YuqueOutlined,
    PaperClipOutlined,
    SettingOutlined,
  },
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    var path = this.$route.path;
    var arr = ["/wang", "/suyuan", "/zhuanjia", "/meilin", "/zixun"];
    for (var i = 0; i < arr.length; i++) {
      if (path.indexOf(arr[i]) == 0) {
        this.active = i;
        break;
      }
    }
  },
  setup() {
    const $router = useRouter();
    const nav = (path) => {
      $router.push({ path });
    };

    const openUrl = (url) => {
      window.open(url);
    };

    return {
      nav,
      openUrl,
    };
  },
});
