import type { DefineComponent } from 'vue'

const component = Object.create(null)
/* eslint-disable */
import Echart from './echartCanvas/index'

import TopBar from "./topbar/index.vue";
import myicon from "./myicon/index.vue";
import paging from "./paging/index.vue";
import rightbackbtn from "./rightbackbtn/index.vue";
import imageviewer from "./imageviewer/index.vue";

component.install = function (vue: DefineComponent) {
  // ECharts 图表渲染
  vue.component('echart', Echart)
  vue.component('topbar', TopBar)
  vue.component('myicon', myicon)
  vue.component('paging', paging)
  vue.component('rightbackbtn', rightbackbtn)
  vue.component('imageviewer', imageviewer)
}
export default component
