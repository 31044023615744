<template>
  <div id="x-nav-bar">
    <div class="x-nav-bar-icon" @click="changeShow">导航</div>
    <div class="x-nav-bar-items-box" v-show="show">
      <div class="x-nav-bar-item" @click="changeRoute('/')">
        <span>大数据</span>
      </div>
      <div class="x-nav-bar-item" @click="changeRoute('/monitor')">
        <span>监控大屏</span>
      </div>
      <div class="x-nav-bar-item" @click="changeRoute('/map-nav')">
        <span>地图导航</span>
      </div>
      <div class="x-nav-bar-item" @click="changeRoute('/expert-system')">
        <span>专家系统</span>
      </div>
      <div class="x-nav-bar-item" @click="goAdmin()">
        <span>管理后台</span>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

import { useRouter } from 'vue-router'
const show = ref(false);
export default {
  name: 'x-nav-bar',
  setup() {
    const changeShow = () => {
      show.value = !show.value;
    };
    const $router = useRouter();
    const changeRoute = (path) => {
      changeShow();
      $router.push({ path });
    };
    const goAdmin = () => {
      let url = 'https://pingwu.leleshuju.com';
      if(process.env.NODE_ENV == "development") {
        url = 'http://localhost:8081';
      }
      window.open(url, '_blank');
    };
    return {
      show,
      changeShow,
      $router,
      changeRoute,
      goAdmin,
    }
  }
}
</script>

<style scoped>

#x-nav-bar{
  height: 30px;
  position: fixed;
  z-index: 9999999;
  bottom: 10px;
  display: flex;
}
.x-nav-bar-icon{
  color: white;
  background-color: black;
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 15px;
  cursor: pointer;
}
.x-nav-bar-items-box{
  display: flex;
  position: relative;
  flex-direction: column;
  top: -130px;
  left: -30px;
}
.x-nav-bar-item{
  color: white;
  background-color: black;
  border: 1px solid white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

</style>