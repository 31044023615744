
import XNavBar from "@/components/x-nav-bar/index.vue";
import TopNavBar from "@/components/top-nav-bar/index.vue";
import TopBar from "@/components/topbar/index.vue";
export default {
  components: {
    "x-nav-bar": XNavBar,
    TopNavBar,
    TopBar,
  },
  setup() {
    return {};
  },
};
